/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useForm } from '@formspree/react';

export default function ContactForm({ formText }) {
  const [userEmail, setUserEmail] = useState('');
  const [state, handleSubmit] = useForm('xoqrrben', {
    data: {
      subject: `Enquiry from ${userEmail}`,
    },
  });

  if (state.succeeded) {
    return (
      <>
        <section id="contact-form">
          <div className="wrapper">
            <form className="success-content">
              <span>🎉</span>
              <h2>Form Submitted!</h2>
              <p>Thank you! We will contact you soon.</p>
            </form>
          </div>
        </section>
      </>
    );
  }

  // useEffect(() => {
  //   const successModal = document.querySelector('.success-modal');
  //   console.log(successModal);
  //   if (state.succeeded) {
  //     successModal.classList.add('show');
  //   }
  // }, [state.succeeded]);

  return (
    <>
      <section id="contact-form">
        <div className="wrapper">
          <form
            method="POST"
            onSubmit={handleSubmit}
            className="has-submitting-state"
          >
            <p className="mono form-text">{formText}</p>

            <div className="form-content">
              <div className="two-col-group">
                <div className="form-group">
                  <label htmlFor="name">Full Name*</label>
                  <input
                    id="name"
                    type="text"
                    name="Applicant Name"
                    required
                    placeholder=" "
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email*</label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    onChange={(e) => setUserEmail(e.target.value)}
                    required
                    placeholder=" "
                  />
                </div>
              </div>

              <div className="form-group purpose">
                <span className="label">Purpose*</span>
                <div className="three-col-group">
                  <div className="radio-group">
                    <input
                      id="looking-for"
                      type="radio"
                      name="Purpose"
                      value="I'm looking for work"
                    />
                    <label htmlFor="looking-for">I'm looking for work</label>
                  </div>
                  <div className="radio-group">
                    <input
                      id="looking-to"
                      type="radio"
                      name="Purpose"
                      value="I'm looking to hire"
                      defaultChecked
                    />
                    <label htmlFor="looking-to">I'm looking to hire</label>
                  </div>
                  <div className="radio-group">
                    <input
                      id="something-else"
                      type="radio"
                      name="Purpose"
                      value="Something else"
                    />
                    <label htmlFor="something-else">Something else</label>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Message*</label>
                <textarea name="Message" required placeholder=" " />
              </div>

              <div className="cta">
                <button type="submit" disabled={state.submitting}>
                  Submit form
                </button>
              </div>
            </div>

            <div className={`submitting ${state.submitting && 'show'}`}>
              <svg className="dashed-circle" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="48"
                  strokeDasharray="10, 10"
                  fill="transparent"
                  stroke="#f24e4e"
                  strokeWidth="2"
                />
              </svg>
              <p>Submitting</p>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
