import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import IconLinkedIn from '../../icons/IconLinkedIn';
import IconFacebook from '../../icons/IconFacebook';
import IconTwitter from '../../icons/IconTwitter';
import IconInstagram from '../../icons/IconInstagram';
// import IconPhone from '../../icons/IconPhone';
// import IconEmail from '../../icons/IconEmail';

export default function Connect() {
  const data = useStaticQuery(graphql`
    {
      sanityContactpage {
        members {
          pic {
            asset {
              gatsbyImageData
            }
          }
          name
          designation
          socials {
            linkedIn
            phone
            email
          }
        }
      }
      sanitySiteSettings {
        email
        email2
        contactIreland {
          address
          phone
        }
        contactBahrain {
          address
          phone
        }
        mediaLinks {
          linkedIn
          twitter
          facebook
          instagram
        }
      }
    }
  `);
  // const allMembers = data.sanityContactpage.members;
  const { email, email2, contactIreland, contactBahrain, mediaLinks } =
    data.sanitySiteSettings;

  return (
    <>
      <section id="connect-with-us">
        <div className="wrapper centered">
          <div className="connect-content">
            <div className="connect">
              <div className="connect-card email">
                <h3>Email us</h3>
                <a href={`mailto:${email}`} className="link">
                  {email}
                </a>
                <a href={`mailto:${email2}`} className="link">
                  {email2}
                </a>
              </div>
              <div className="connect-card find">
                <h3>Find us</h3>
                <p>
                  Ireland:{' '}
                  <a
                    href="https://www.google.com/maps/place/nineDots+Technology+Recruitment/@53.344405,-6.2500[…]e0a280c?sa=X&ved=2ahUKEwicvbCHteb3AhWeSEEAHSQfChoQ_BJ6BAhLEAU"
                    target="_blank"
                    className="link"
                    rel="noreferrer"
                  >
                    {contactIreland.address}
                  </a>
                  {', '}
                  <a href={`tel:${contactIreland.phone}`} className="link">
                    {contactIreland.phone}
                  </a>
                </p>
                <p>
                  Bahrain: {contactBahrain.address}
                  {contactBahrain.phone && (
                    <a href={`tel:${contactBahrain.phone}`} className="link">
                      , {contactBahrain.phone}
                    </a>
                  )}
                </p>
                <div className="socials">
                  {mediaLinks.linkedIn && (
                    <a
                      href={mediaLinks.linkedIn}
                      target="_blank"
                      className="icon"
                      rel="noreferrer"
                    >
                      <IconLinkedIn />
                    </a>
                  )}
                  {mediaLinks.twitter && (
                    <a
                      href={mediaLinks.twitter}
                      target="_blank"
                      className="icon"
                      rel="noreferrer"
                    >
                      <IconTwitter />
                    </a>
                  )}
                  {mediaLinks.facebook && (
                    <a
                      href={mediaLinks.facebook}
                      target="_blank"
                      className="icon"
                      rel="noreferrer"
                    >
                      <IconFacebook />
                    </a>
                  )}
                  {mediaLinks.instagram && (
                    <a
                      href={mediaLinks.instagram}
                      target="_blank"
                      className="icon"
                      rel="noreferrer"
                    >
                      <IconInstagram />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
